// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-360-viewer-js": () => import("./../src/pages/360-viewer.js" /* webpackChunkName: "component---src-pages-360-viewer-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-description-js": () => import("./../src/pages/description.js" /* webpackChunkName: "component---src-pages-description-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-project-page-js": () => import("./../src/pages/projectPage.js" /* webpackChunkName: "component---src-pages-project-page-js" */),
  "component---src-pages-projects-list-js": () => import("./../src/pages/projectsList.js" /* webpackChunkName: "component---src-pages-projects-list-js" */)
}

